body {
  font-family: PT Sans, Verdana, Geneva, Tahoma, sans-serif;
}

.App {
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
  letter-spacing: 0.03em;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.Content {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  margin: 0.4em;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
